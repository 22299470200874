<!-- 待审核的详情 -->
<template>
  <div class="details">
    <!-- 导航标签 -->
    <div class="flex biao align-items">
      <!-- <div>{{ "当前位置 : " }}</div>
      <div style="opacity: 0">{{ "0" }}</div>
      <div class="">{{ "首页" }}</div>
      <div class="zuojian">
        <img
          src="../../assets/img/xinban/heizuojian.png"
          class="w-full h-full"
          alt=""
        />
      </div>
      <div class="yanse">{{ "详情" }}</div> -->
    </div>
    <!-- 发布主题与撤回 -->
    <div class="chehui flex space-between align-items">
      <div class="flex">
        <el-button type="primary" @click="fabu">发表新帖</el-button>
        <el-button type="info" @click="huifa(wanzhang.usename, 0)"
          >跟帖评论</el-button
        >
      </div>
      <div class="flex align-items che cursor_pointer" @click="fanhui">
        <img src="../../assets/img/xinban/fanhui.png" class="huitui" alt="" />
        <div>{{ "返回列表" }}</div>
      </div>
    </div>
    <!-- 帖子详情 -->
    <div class="tiezi">
      <!-- 详情 -->
      <div class="w-full xianq">
        <div class="w-full xian_head flex">
          <div class="flex justify-content align-items zuo" :style="{backgroundImage: 'url(' + bg2 + ')' }" style="background-size: 100% 100%;">
            <div class="mr33 opacity">
              <span ref="import">{{ "查看 :" }}</span>
              <span class="opacity">我</span>
              <span class="yanse">{{
                wanzhang == null ? 0 : wanzhang.views
              }}</span>
            </div>
            <div class="opacity">
              <span>{{ "回复 :" }}</span>
              <span class="opacity">我</span>
              <span class="yanse">{{
                wanzhang == null ? 0 : wanzhang.comments
              }}</span>
            </div>
          </div>
          <div class="you flex1">
            <div>
              <span class="zixu">
                <span
                  class="zi"
                  v-if="wanzhang == null ? 0 : wanzhang.type == '咨询'"
                >
                  【{{ "咨询" }}】
                </span>
                <span
                  class="hong"
                  v-else-if="wanzhang == null ? 0 : wanzhang.type == '投诉'"
                >
                  【{{ "投诉" }}】
                </span>
                <span
                  class="lvz"
                  v-else-if="wanzhang == null ? 0 : wanzhang.type == '建议'"
                >
                  【{{ "建议" }}】
                </span>
                <span class="danwei">
                  【{{ wanzhang == null ? "" : wanzhang.unit_name }}】
                </span>
              </span>
              <img
                src="../../assets/img/one/img1.png"
                v-if="wanzhang == null ? '未审核' : wanzhang.status == '未审核'"
                class="shouli"
                alt=""
              />
              <img
                src="../../assets/img/one/img1.png"
                v-else-if="
                  wanzhang == null ? '未审核' : wanzhang.status == '未受理'
                "
                class="shouli"
                alt=""
              />
              <img
                src="../../assets/img/one/yishouli.png"
                v-else-if="
                  wanzhang == null ? '未审核' : wanzhang.status == '已受理'
                "
                class="shouli"
                alt=""
              />
              <img
                src="../../assets/img/one/yihuifu.png"
                v-else-if="
                  wanzhang == null ? '未审核' : wanzhang.status == '已回复'
                "
                class="shouli"
                alt=""
              />
              <img
                src="../../assets/img/one/img2.png"
                v-else-if="
                  wanzhang == null ? '未审核' : wanzhang.status == '已办结'
                "
                class="shouli"
                alt=""
              />
            </div>
            <div class="biaoti">
              {{ wanzhang == null ? "" : wanzhang.title }}
            </div>
            <!-- <img src="../../assets/img/xinban/yishouli.png" class="shouli" alt=""> -->
          </div>
        </div>
        <!-- 楼主 -->
        <!-- @click="yonghuqk(wanzhang.useid)" -->
        <div class="louzhu flex w-full">
          <div class="zuo flex">
            <div
              class="touxinag cursor_pointer"
             
            >
              <img
                v-if="wanzhang == null ? '' : wanzhang.useimage != ''"
                :src="
                  wanzhang == null
                    ? '../../assets/img/one/weizhiyonghu.png'
                    : wanzhang.useimage
                "
                class="w-full h-full"
                alt=""
              />
              <img
                v-else
                src="../../assets/img/one/weizhiyonghu.png"
                class="w-full h-full"
                alt=""
              />
            </div>
            <div
              class="ming"
              :class="{ hz: wanzhang == null ? 0 : wanzhang.official == 1 }"
            >
              {{ wanzhang == null ? "" : wanzhang.usename }}
            </div>
            <div class="flex ziliao align-items">
              <div class="flex column align-items">
                <div>{{ wanzhang == null ? 0 : wanzhang.zhuti }}</div>
                <div class="fontone">{{ "主题" }}</div>
              </div>
              <div class="xian"></div>
              <div class="flex column align-items">
                <div>{{ wanzhang == null ? 0 : wanzhang.huitie }}</div>
                <div class="fontone">{{ "回帖" }}</div>
              </div>
            </div>
            <div class="flex align-items mt15">
              <div class="flex align-items">
                <div
                  class="flex align-items"
                  v-if="
                    wanzhang == null
                      ? '离线'
                      : wanzhang.userstatus_txt == '离线'
                  "
                >
                  <div class="dian"></div>
                  <div class="fontwo">{{ "离线" }}</div>
                </div>
                <div class="flex align-items" v-else>
                  <div class="dian lv"></div>
                  <div class="fontwo lvz">{{ "在线" }}</div>
                </div>
              </div>
              <div
                :class="{
                  opacity: wanzhang == null ? '0' : wanzhang.useid == uidb(),
                }"
              >
                <div
                  class="guanzhu"
                  v-if="wanzhang == null ? 0 : wanzhang.followtag == 0"
                >
                  {{ "未关注" }}
                </div>
                <div class="yiguan" v-else>
                  {{ "已关注" }}
                </div>
              </div>
            </div>
            <img
              src="../../assets/img/xinban/louzhu.png"
              class="louzhubiaoji"
              alt=""
            />
          </div>
          <div class="you flex1 flex column">
            <!-- 发布信息 -->
            <div class="fabuxinxi flex align-items space-between">
              <div class="fontone">
                {{
                  `发表于 ${wanzhang == null ? "昨天" : wanzhang.articletime} ${
                    wanzhang == null ? "PC端" : wanzhang.port
                  } 来自${wanzhang == null ? "" : wanzhang.province}${
                    wanzhang == null ? "" : wanzhang.district
                  }`
                }}
              </div>
              <!-- <div class="fontTwo">{{ `编号${wanzhang == null ? '202304040002' : wanzhang.cardsalt}` }}</div> -->
              <div class="fontTwo">
                编号:{{ wanzhang == null ? "" : wanzhang.cardsalt }}
              </div>
            </div>
            <!-- 文章主题 -->
            <div class="wenzhanzhuti flex">
              <div>
                <!-- html标签 -->
                <div
                  v-if="wanzhang == null ? '' : wanzhang.port == 'PC端'"
                  class="wenzhan"
                  v-html="wanzhang == null ? '' : wanzhang.content"
                ></div>
                <!-- 小程序展示 -->
                <div v-else>
                  <!-- 小程序文字内容 -->
                  <div v-html="wanzhang == null ? '' : wanzhang.content"></div>
                </div>
                <!-- 图片视频 -->
                <div style="margin-top: 20px">
                  <div v-if="wanzhang == null ? '' : wanzhang.port == '手机端'">
                    <!-- 图片展示 -->
                    <div class="tuzhan">
                      <div
                        class="tupian"
                        v-for="(item, index) in wanzhang == null
                          ? ''
                          : wanzhang.appletimages"
                        :key="index"
                      >
                        <div class="tunei">
                          <el-popover
                            placement="top-start"
                            title=""
                            popper-class="xuanfu"
                            width="50"
                            trigger="hover"
                          >
                            <div class="xiazai" @click="tuxiazia(item.url)">
                              {{ "下载" }}
                            </div>
                            <div class="tupianwen" slot="reference">
                              <span>{{ item.filename }}</span>
                              <span>({{ item.size }})</span>
                            </div>
                          </el-popover>
                          <el-image
                            class="img"
                            :src="item.url"
                            :preview-src-list="tuarr(wanzhang.appletimages)"
                          >
                          </el-image>
                        </div>
                      </div>
                    </div>
                    <!-- 小程序视频展示 -->
                    <div class="shizhan">
                      <div
                        class="shipins"
                        v-for="(item, index) in wanzhang == null
                          ? ''
                          : wanzhang.appletvideos"
                        :key="index"
                      >
                        <el-popover
                          placement="top-start"
                          title=""
                          popper-class="xuanfu"
                          width="50"
                          trigger="hover"
                        >
                          <div class="xiazai" @click="tuxiazia(item.url)">
                            {{ "下载" }}
                          </div>
                          <div class="tupianwen flex" slot="reference">
                            <span class="white-space">{{ item.filename }}</span>
                            <span class="white-space">({{ item.size }})</span>
                          </div>
                        </el-popover>
                        <div class="shipin">
                          <Videoplayer :mp4Url="item.url"></Videoplayer>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 附件 -->
                  <div
                    class="flex ful"
                    v-if="
                      wanzhang == null ? '' : wanzhang.appletfiles.length != 0
                    "
                  >
                    <div>{{ "附件 :" }}</div>
                    <div class="fuliebiao">
                      <div
                        @click="tuxiazia(item.url)"
                        class="wenjianming"
                        v-for="(item, index) in wanzhang == null
                          ? ''
                          : wanzhang.appletfiles"
                        :key="index"
                      >
                        <span class="hov">{{ item.filename }}</span>
                        <span class="xiazi">({{ item.size }})</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 官方回复 -->
                <div
                  class="flex w-full justify-content mt33"
                  v-if="wanzhang == null ? '' : wanzhang.huifutime != ''"
                >
                  <!-- <div class="guangfang">{{ `本主题由 ${'管理员'} 于 ${'7小时前'} 已回复` }}</div> -->
                  <div class="guangfang">
                    {{ wanzhang == null ? "" : wanzhang.huifutime }}
                  </div>
                </div>
              </div>
              <div class="flex caozuo">
                <div></div>
                <div class="flex align-items">
                  <!-- 收藏 -->
                  <div
                    class="bianju"
                    @click="
                      shouchang(wanzhang.cardsalt, wanzhang.collectiontag)
                    "
                  >
                    <div class="flex align-items cursor_pointer">
                      <img
                        src="../../assets/img/xinban/shoucang.png"
                        v-if="
                          wanzhang == null ? 0 : wanzhang.collectiontag == 0
                        "
                        class="icon"
                        alt=""
                      />
                      <img
                        src="../../assets/img/one/yishoucnag.png"
                        v-else
                        class="icon"
                        alt=""
                      />
                      <div>{{ "收藏" }}</div>
                    </div>
                  </div>
                  <!-- 点赞 -->
                  <div
                    class="bianju"
                    @click="dianzan(wanzhang.cardsalt, wanzhang.liketag, 0)"
                  >
                    <div class="flex align-items cursor_pointer">
                      <img
                        src="../../assets/img/xinban/dainzan.png"
                        v-if="wanzhang == null ? 0 : wanzhang.liketag == 0"
                        class="icon"
                        alt=""
                      />
                      <img
                        src="../../assets/img/one/img19.png"
                        v-else
                        class="icon"
                        alt=""
                      />
                      <div>{{ "点赞" }}</div>
                    </div>
                  </div>
                  <!-- 举报 -->
                  <div
                    class="bianju"
                    @click="jubao(wanzhang.cardsalt, 0, wanzhang.usename)"
                  >
                    <div class="flex align-items cursor_pointer">
                      <img
                        src="../../assets/img/xinban/jubao.png"
                        class="icon"
                        alt=""
                      />
                      <div class="yanse">{{ "举报" }}</div>
                    </div>
                  </div>
                  <!-- 楼层 -->
                  <!-- <div class="bianju">
                      <div class="cursor_pointer">{{ '1' }}楼</div>
                                          </div> -->
                  <!-- 回复 -->
                  <div class="bianju">
                    <div
                      class="cursor_pointer yanse"
                      @click="huifa(wanzhang.usename, 0)"
                    >
                      {{ "回复" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 线 -->
    <div class="xian"></div>

    <!-- 可移动弹框 -->
    <div class="yi" v-if="fayan == true">
      <!-- 标题 -->
      <div class="yidongquyu flex" @click.left="touchmove">
        <div class="flex">
          <div>{{ "回复" }}</div>
          <div>{{ beihuifuzhe }}</div>
        </div>
        <div class="guanbi" @click="kaiguan">
          <img
            src="../../assets/img/xinban/close-circle.png"
            class="w-full h-full"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// 引入富文本
import Bg2 from '@/assets/img/xinban/tztopbg.png'
import API from "@/api/modules/details.js";
import Cookies from "js-cookie";
import Bus from "@/assets/ligature.js";
import Videoplayer from "../video/index.vue"; // 视频播放组件
export default {
  components: {
    Videoplayer,
  },
  data() {
    return {
      total: 100,
      cishu: 0, //富文本清空
      bjq: "", // 编辑器内容
      fuwenben: "",
      fwb: "",
      wanzhang: null,
      token: undefined, //token状态
      pingList: [],
      page: 1,
      zongye: 10,
      fayan: false, //回复弹框
      beihuifuzhe: "",
      cijiid: "", //次级评论的id
      dialogVisible: false, //举报弹框
      zid: "", //作者id
      bg2: Bg2,
    };
  },
  mounted() {
    localStorage.setItem("path", this.$route.path);
    this.tiezi();
  },
  created() {
    Bus.$on("guanbis", () => {
      this.closes();
    });
    // this.$refs.import.click()
  },
  methods: {
    // 判断是否有top提示
    gf(str) {
      let a = str.split(",");
      if (a.length > 2) {
        return false;
      } else {
        return true;
      }
    },
    // 部门长度
    buchandu(str) {
      let a = str.split(",");
      if (a.length <= 2) {
        return a.toString();
      } else {
        return a[0] + "," + a[1] + "...";
      }
    },
    // 图片下载
    tuxiazia(url) {
      const fileUrl = url;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "your-file.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 图片数组
    tuarr(obj) {
      let a = [];
      obj.forEach((item) => {
        a.push(item.url);
      });
      return a;
    },
    // 楼层
    louceng(num) {
      return (this.page - 1) * 10 + num + 2 + "楼";
    },
    // 跳附件
    taiochuwuxing() {
      window.open(routeData.href, "_blank");
    },
    uidb() {
      let a = localStorage.getItem("uid");
      return a;
    },
    // 其他用户
    yonghuqk(id) {
      return
      // console.log(id == this.$store.state.loginMessage.uid, 'sss--');
      // console.log(this.$store.state.loginMessage.uid,'222==');
      // 记录id
      this.$store.state.loginMessage.id = id;
      // 判断此id是否等于自己的id
      // console.log(localStorage.getItem('uid'),'2222');
      if (id == localStorage.getItem("uid")) {
        this.jumps("persomal", this.$store.state.loginMessage);
      } else {
        this.jumps("other", this.$store.state.loginMessage);
      }
    },
    // 关闭举报弹框
    closes() {
      this.dialogVisible = false;
    },
    // 举报弹框
    async jubao(id, type, nams) {
      this.$message({
        message: "该贴尚未通过审核,暂时无法举报!",
        center: true,
        type: "error",
      });
    },
    // 关闭回复弹框
    kaiguan() {
      this.fayan = false;
    },
    // 打开回复弹框
    huifa(name, id) {
      this.$message({
        message: "该贴尚未通过审核,暂时无法评论!",
        center: true,
        type: "error",
      });
    },
    // 获取鼠标移动事件
    touchmove(event) {
      let a = event.offsetX;
      // 获取y 坐标
      let b = event.offsetY;
      // console.log(a, b);
    },
    // 返回列表页
    fanhui() {
      this.$router.go(-1);
    },
    // 进入发布页面
    fabu() {
      this.jumps("issue", this.$store.state.loginMessage);
    },
    // 评论点赞
    async pingDianzan(id, num, type) {
      this.$message({
        message: "该贴尚未通过审核,暂时无法点赞!",
        center: true,
        type: "error",
      });
    },
    // 评论收藏
    async pingShoucang(id, num) {
      this.$message({
        message: "该贴尚未通过审核,暂时无法收藏!",
        center: true,
        type: "error",
      });
    },
    // 点赞帖子
    async dianzan(id, num) {
      this.$message({
        message: "该贴尚未通过审核,暂时无法点赞!",
        center: true,
        type: "error",
      });
    },
    // 收藏帖子
    async shouchang(id, num) {
      this.$message({
        message: "该贴尚未通过审核,暂时无法收藏!",
        center: true,
        type: "error",
      });
    },
    // 关注
    async guanz(id, num) {
      this.$message({
        message: "该贴尚未通过审核,暂时无法评论!",
        center: true,
        type: "error",
      });
    },
    // 次级评论
    async ciping(str) {
      this.$message({
        message: "该贴尚未通过审核,暂时无法评论!",
        center: true,
        type: "error",
      });
    },
    // 发表评论
    async pinglun() {
      this.$message({
        message: "该贴尚未通过审核,暂时无法评论!",
        center: true,
        type: "error",
      });
    },
    // 帖子详情
    async tiezi() {
      let res = await API.articleInfo({
        cardid: this.$store.state.loginMessage.cardsalt,
      }).then((res) => {
        // console.log(res, "22");
        if (res.code == 1) {
          if (res.data.status != "待审核") {
            this.$store.state.loginMessage.cardsalt = res.data.cardsalt;
            // return
            this.jump("details", this.$store.state.loginMessage);
          }
          this.wanzhang = res.data;
          document.title = this.wanzhang.title
          this.zid = res.data.useid;
          window.scrollTo(0, 0);
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
      // this.yichu()
    },
    // 获得富文本
    chang(res) {
      this.fuwenben = res;
      // console.log(this.fuwenben, "22323");
    },
  },
};
</script>
  
<style scoped lang="scss">
.details {
  width: 1200px;
}

.biao {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  margin: 16px 0 22px 0;

  .zuojian {
    width: 18px;
    height: 18px;
    margin: 0 3px;
  }

  .yanse {
    color: #0653ac;
  }
}

.chehui {
  ::v-deep(.el-button--primary) {
    width: 110px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    border: 0px solid red;
    margin-right: 17px;
  }

  ::v-deep(.el-button--info) {
    // width: 82px;
    height: 44px;
    background: #86898d;
    border-radius: 2px;
  }

  .che {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #2670c5;
    letter-spacing: 0;
    font-weight: 400;

    .huitui {
      width: 15.69px;
      height: 13.07px;
      margin-right: 6.15px;
    }
  }
}

// 帖子详情
.tiezi {
  background: #f4f8fd;
  border: 1px solid #d6dbe3;
  width: 100%;
  margin-top: 16px;

  .xianq {
    // border-bottom: 4px solid #F1F1F1;
  }

  .xian_head {
    min-height: 58px;
    background: #ffffff;
    border-bottom: 4px solid #f1f1f1;

    .zuo {
      width: 227px;
      // height: 100%;
      border-right: 3px solid #d6dbe3;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #484c51;
      letter-spacing: 0;
      font-weight: 400;

      .mr33 {
        margin-right: 33px;
      }

      .yanse {
        color: #fb8a2b;
      }
    }

    .you {
      padding: 5px 23px;
      width: calc(1200px - 227px);
      .zixu {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        letter-spacing: 0;
        font-weight: 400;
        // white-space: nowrap;

        .zi {
          color: #34348f;
        }

        .lv {
          color: #00a576;
        }

        .hong {
          color: #ff5637;
        }
      }

      .danwei {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #51565c;
        letter-spacing: 0;
        font-weight: 400;
        // white-space: nowrap;
      }

      .biaoti {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #18191a;
        letter-spacing: 0;
        font-weight: 500;
      }

      .shouli {
        width: 61px;
        height: 19.7px;
        display: inline-block;
        vertical-align: sub;
        // margin-left: 5px;
      }
    }
  }

  .louzhu {
    background: #ffffff;
    min-height: 364px;
    border-bottom: 4px solid #f1f1f1;

    .zuo {
      width: 227px;
      border-right: 3px solid #d6dbe3;
      padding: 40px 0 0 0;
      background: #f4f8fd;
      flex-direction: column;
      align-items: center;
      position: relative;

      .fyrbiaoji {
        position: absolute;
        left: 0;
        top: 0;
        width: 75px;
        height: 25px;
      }

      .louzhubiaoji {
        position: absolute;
        right: -1px;
        top: 0;
        width: 43.73px;
        height: 40.06px;
      }

      .touxinag {
        width: 110px;
        height: 110px;
        background: #fff;
        border: 1px solid #d6dbe3;
        padding: 5px;
        cursor: pointer;
      }

      .ming {
        margin-top: 8px;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #34348f;
        letter-spacing: 0;
        font-weight: 500;
      }

      .ziliao {
        margin-top: 17px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #27262f;
        letter-spacing: 0;
        font-weight: 400;

        .fontone {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #484c51;
          letter-spacing: 0;
          font-weight: 400;
        }

        .xian {
          width: 1px;
          height: 16px;
          border-left: 1px solid #d6dbe3;
          margin: 0 30px;
        }
      }

      .mt15 {
        margin-top: 15px;
      }

      .dian {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-right: 5px;
        background: #595e64;
      }

      .fontwo {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #484c51;
        letter-spacing: 0;
        font-weight: 400;
      }

      .guanzhu {
        margin-left: 17px;
        width: 62px;
        height: 26px;
        border-radius: 17px;
        border: 1px solid #868d9d;
        text-align: center;
        line-height: 26px;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        color: #858ea0;
        letter-spacing: 0;
        font-weight: 500;
      }

      .yiguan {
        margin-left: 17px;
        width: 62px;
        height: 26px;
        border-radius: 17px;
        border: 1px solid #fb8a2b;
        text-align: center;
        background: #fb8a2b;
        line-height: 26px;
        cursor: pointer;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        color: #fff;
        letter-spacing: 0;
        font-weight: 500;
      }
    }

    .you {
      width: calc(1200px - 227px);
      .fabuxinxi {
        width: 100%;
        height: 35px;
        padding: 0 19px 0 32px;
        border-bottom: 1px dashed transparent;
        background: linear-gradient(#fff, #fff) padding-box,
          repeating-linear-gradient(
            -45deg,
            #d6dbe3 0,
            #d6dbe3 0.3em,
            #fff 0,
            #fff 0.6em
          );
        /* 0.3em设置虚线点的宽度或长度；0.6em设置虚线点的间距 */
      }

      .fontone {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #484c51;
        letter-spacing: 0;
        font-weight: 400;
      }

      .fontTwo {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #27262f;
        letter-spacing: 0;
        font-weight: 400;
      }

      .wenzhanzhuti {
        padding: 19px 25px 21px 33px;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
      }

      .mt33 {
        margin-top: 33px;
      }

      .guangfang {
        padding: 7px 26px;
        background: #e8effa;
        border-radius: 2px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #0653ac;
        letter-spacing: 0;
        font-weight: 400;
      }

      .caozuo {
        margin-top: 65px;
        justify-content: space-between;
      }

      .bianju {
        margin-right: 20px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #484c51;
        letter-spacing: 0;
        font-weight: 400;

        .icon {
          width: 18px;
          height: 18px;
          margin-right: 3px;
        }

        .yanse {
          color: #2670c5;
        }
      }
    }
  }
}

.yema {
  margin: 30px 0 30px 0;
  display: flex;
  justify-content: end;

  ::v-deep(.el-pager) {
    .number {
      background: #fff;
      border: 0.5px solid #d6dbe3;
    }
  }

  ::v-deep(.el-pagination__jump) {
    margin-left: 0 !important;
  }
}

.xian {
  width: 100%;
  height: 1px;
  border-bottom: 4px solid #f1f1f1;
  margin-bottom: 9px;
}

.fwb {
  margin-bottom: 10px;

  .huifu {
    width: 227px;
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #36383a;
    letter-spacing: 0;
    font-weight: 400;
    // margin-right: 28px;
    background: #f4f8fd;
    text-align: center;
    padding-top: 20px;
  }

  .wb {
    flex: 1;
    position: relative;

    .weideng {
      position: absolute;
      width: 100%;
      height: 100%;
      // background: #000;
      left: 0;
      top: 0;
      z-index: 100000;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.zanshi {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
}

.act {
  border-bottom: 0px solid red !important;
}

.yi {
  position: fixed;
  width: 650px;
  height: 350px;
  border: 5px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  left: calc(50% - 325px);
  top: calc(50% - 175px);
  z-index: 100000;

  .yidongquyu {
    width: 100%;
    height: 36px;
    // cursor: move;
    align-items: center;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;
    padding: 0 10px;
    justify-content: space-between;

    .guanbi {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
}

:deep(.el-dialog) {
  width: 410px;
  border-radius: 17px;
  overflow: hidden;
}

:deep(.el-dialog__header) {
  padding: 0px;
}

:deep(.el-dialog__headerbtn) {
  right: 10px !important;
  top: 10px !important;
}

:deep(.el-dialog__body) {
  padding: 0;
}

.lv {
  background: #04af23 !important;
}

.lvz {
  color: #04af23 !important;
}

.huiping {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #18191a;
  letter-spacing: 0;
  font-weight: 500;
  margin: 10px 0;
}

.hz {
  color: #c90e0e !important;
}

.heqiang {
  width: 227px;
}

.btn {
  // margin-top: 10px;

  ::v-deep(.el-button--primary) {
    background: #1c69c6;
    border-radius: 2px;
    width: 120px;
    height: 44px;
    border: 0px solid red;
  }
}

.fujian {
  display: flex;

  .fu {
    margin-right: 5px;
  }

  .papazuo {
    display: flex;
    // width: 500px;
    // flex-wrap:wrap;
    flex-direction: column;

    .lou {
      margin-bottom: 5px;
      // margin-bottom: 5px;
      color: #0653ac;
      cursor: pointer;
    }
  }
}

.tuzhan {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .tupian {
    width: 100%;
    // height: 200px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    :deep(.img) {
      width: 640px;
      // height: 180px;
      // height: 100%;
    }
  }

  .tunei {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tupianwen {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #484c51;
    letter-spacing: 0;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      color: #1c69c6;
    }
  }
}

.shizhan {
  width: 100%;
  padding: 0 20px;
  // height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 20px;

  .tupianwen {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #484c51;
    letter-spacing: 0;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      color: #1c69c6;
    }
  }

  .shipins {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .shipin {
    width: 640px;
    height: 360px;
    margin-bottom: 5px;
  }
}

.tufu {
  position: relative;

  .fugai {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.ful {
  margin-top: 40px;

  .wenjianming {
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #1c69c6;
    letter-spacing: 0;
    line-height: 21px;
    font-weight: 500;
    cursor: pointer;

    .hov:hover {
      color: #ff0424 !important;
    }

    .xiazi {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #7b8391;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
::v-deep(.wenzhan) {
  p {
    img {
      max-width: 100% !important;
    }
  }
}
</style>